import { DirectusClient } from "@/apiconnection/dbClient";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  data() {
    const client: DirectusClient | null = null;
    return { client };
  },
  methods: {
    async getClient() {
      this.client = await this.$store.dispatch("getDirectusClient");
    },
    async clientLogin() {
      await this.client.login();
    }
  }
});
