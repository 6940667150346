
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "RootApp",
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    }
  }
});
