
import { defineComponent } from "vue";

import activeHours from "../mixins/activeHours";

export default defineComponent({
  name: "DayColumn",
  components: {},
  mixins: [activeHours],
  props: {
    inWeek: {
      type: Boolean,
      default: false
    },
    compressNotes: {
      type: Boolean,
      default: false
    }
  }
});
