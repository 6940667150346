<template>
  <div id="nav" class="mb-2 w-full px-4 py-4 bg-gray-200 shadow-lg">
    <span class="my-4 text-md font-monospace font-bold">MoodCalendar</span>
    <router-link v-if="isLoggedIn" class="block md:inline-block text-center my-1 mx-1 md:mx-4 py-2 px-2 md:px-5 bg-blue-700 rounded-lg text-white hover:shadow-md" to="/">{{T("Today")}}</router-link>
    <router-link v-if="isLoggedIn" class="block md:inline-block text-center my-1 mx-1 md:mx-4 py-2 px-2 md:px-5 bg-blue-700 rounded-lg text-white hover:shadow-md" to="/week">{{T("Week")}}</router-link>
    <router-link v-if="isLoggedIn" class="block md:inline-block text-center my-1 mx-1 md:mx-6 py-2 px-2 md:px-5 bg-gray-800 rounded-lg text-white hover:shadow-md" to="/new-event">{{T("New Event")}}</router-link>
  </div>
  <section class="my-4">
    <router-view />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "RootApp",
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    }
  }
});
</script>

<style lang="scss"></style>
