
import { clone, find, map } from "lodash";
import { subDays, format, startOfWeek, eachDayOfInterval } from "date-fns";
import { de } from "date-fns/locale";
import { defineComponent } from "vue";

import { Day, DayEvent } from "../dbTypes";
import apiClientMixin from "@/mixins/apiClientMixin";

export default defineComponent({
  name: "NewEvent",
  mixins: [apiClientMixin],
  setup() {
    const deFormat = (date: Date) => format(date, "EEE, dd.MM.yyyy", { locale: de });
    const today = new Date();

    return {
      format,
      deFormat,
      today
    };
  },
  data () {
    const days = [];
    const day = this.isoToday;
    const startHour = parseInt(this.formatToday("H"));
    const endHour = parseInt(this.formatToday("H"));
    const mood = 0;
    const note = "";

    return {
      days,
      startHour,
      endHour,
      mood,
      note,
      day
    };
  },
  watch: {
    day(newVal) {
      const newDay = find(this.days, ({ date }) => date === newVal);
      if (!newDay && newVal !== undefined) {
        this.createDay(newVal);
      }
    }
  },
  computed: {
    dayObject() {
      return find(this.days, ({ date }) => date === this.day);
    },
    hours () {
      const hoursArray = Array.from(new Array(24).keys());
      return hoursArray.map((k) => k + 1);
    },
    thisStartOfWeek () { return startOfWeek(this.today, { weekStartsOn: 1 }) },
    dayInterval() {
      return eachDayOfInterval({
        start: subDays(this.today, 5),
        end: this.today
      });
    },
    todayId () {
      return this.dayObject?.id || 0;
    },
    todayEvents () {
      return this.dayObject?.event || [];
    },
    startDate () {
      const startDate = new Date(this.day);
      startDate.setHours(this.startHour);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      return startDate;
    },
    endDate () {
      const endDate = new Date(this.day);
      endDate.setHours(this.endHour);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      endDate.setMilliseconds(0);
      return endDate;
    },
    isoToday (): string {
      return format(new Date(), "yyyy-MM-dd");
    },
    eventHours (): DayEvent[] {
      if (this.todayEvents === null) {
        return [];
      }
      const eventHours: DayEvent[] = map(this.todayEvents, (event: DayEvent) => {
        const startHour = new Date(event.start as string).getHours();
        const endHour = new Date(event.end as string).getHours();
        return {
          startHour,
          endHour
        };
      }) as DayEvent[];

      eventHours.sort((a, b) => a.startHour > b.startHour ? 1 : -1);

      return eventHours;
    },
    lastEventEndHour () {
      const tempEventHours = clone(this.eventHours);
      if (tempEventHours.length > 0) {
        return tempEventHours.pop().endHour;
      }
      return false;
    },
    invalidEvent () {
      if (this.eventHours.length > 0) {
        const valid = this.eventHours.reduce((coll, eventHour) => {
          return ((this.startHour >= eventHour.startHour && this.startHour < eventHour.endHour) ||
          (this.endHour <= eventHour.startHour && this.endHour > eventHour.endHour)) || coll;
        }, false);
        return valid;
      }
      return false;
    },
    eventData () {
      return {
        start: this.startDate,
        end: this.endDate,
        rating: this.mood,
        note: this.note,
        day: this.todayId
      };
    }
  },
  async mounted () {
    await this.getDays();
  },
  methods: {
    formatToday (formatting: string): string {
      return format(new Date(), formatting);
    },
    async storeNewEvent () {
      await this.getClient();
      await this.clientLogin();
      const eventApi = this.client.getItemClient("event");
      eventApi.createOne(this.eventData)
        .then((result) => {
          console.log(result);
          this.$router.push("/");
        })
        .catch((error) => {
          console.error(error);
          this.$router.push("/");
        });
    },
    async createDay(date?: string): Promise<Day> {
      if (date) {
        const dayClient = this.client.getItemClient("day");
        console.log("Today is not available in the database yet. Creating for date: ", date);
        const createResult = await dayClient.createOne({
          date,
          note: ""
        });
        console.log({ createResult });
        this.days.push(createResult);
        return createResult;
      }
    },
    async getDays () {
      await this.getClient();
      await this.clientLogin();
      const dayClient = this.client.getItemClient("day");
      const result = await dayClient.readMany({
        filter: {
          date: {
            _gte: format(subDays(this.today, 5), "yyyy-MM-dd"),
            _lte: this.isoToday
          },
          user_created: "$CURRENT_USER"
        },
        fields: [
          "id",
          "date",
          "event.start",
          "event.end",
          "event.day"
        ]
      });

      let today = null;

      if (result.data.length > 0) {
        this.days = result.data;
        today = find(this.days, ({ date }) => date === this.isoToday);
        console.log({ today });
      }
      this.day = today ? today.date : this.isoToday;
      this.startHour = this.lastEventEndHour || 7;
    }
  }
});
