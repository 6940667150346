<template>
  <div class="px-4">
    <h1 class="text-xl font-bold p-3">{{ T('Your moods today') }}</h1>
    <h2 class="text-lg font-bold p-3">
      <span class="mb-4 md:mb-0  inline-block"> {{T("Color code")}}:</span> <br class="inline-block md:hidden" />
      <span class="border-2 border-black m-2-lr p-2">
        <span class="h-6 w-6 md:w-12 inline-block sign-hours active rated--2">&nbsp;</span> --
      </span>
      <span class="border-2 border-black m-2-lr p-2">
        <span class="h-6 w-6 md:w-12 inline-block sign-hours active rated--1">&nbsp;</span> -
      </span>
      <span class="border-2 border-black m-2-lr p-2">
        <span class="h-6 w-6 md:w-12 inline-block sign-hours active rated-0">&nbsp;</span> 0
      </span>
      <span class="border-2 border-black m-2-lr p-2">
        <span class="h-6 w-6 md:w-12 inline-block sign-hours active rated-1">&nbsp;</span> +
      </span>
      <span class="border-2 border-black m-2-lr p-2">
        <span class="h-6 w-6 md:w-12 inline-block sign-hours active rated-2">&nbsp;</span> ++
      </span>
    </h2>
    <day-column :today="today" />
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import { format } from "date-fns";
import { Day } from "../dbTypes";
import apiClientMixin from "@/mixins/apiClientMixin";
import DayColumn from "@/components/DayColumn.vue";

export default defineComponent({
  name: "TodayView",
  components: { DayColumn },
  mixins: [apiClientMixin],
  data () {
    const today: Day|null = null;
    return { today };
  },
  computed: {
    isoToday (): string {
      return format(new Date(), "yyyy-MM-dd");
    }
  },
  async mounted () {
    await this.getMoodData();
  },
  methods: {
    async getMoodData () {
      await this.getClient();
      await this.clientLogin();
      const dayClient = this.client.getItemClient("day");
      dayClient.readMany({
        filter: {
          date: { _eq: this.isoToday },
          user_created: "$CURRENT_USER"
        },
        fields: [
          "date",
          "note",
          "event.start",
          "event.end",
          "event.rating",
          "event.note",
          "event.day"
        ]
      }).then((result) => {
        console.log(result);
        if (result.data[0]) {
          this.today = result.data[0];
        }
      });
    }
  }
});
</script>
