
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Login",
  setup () {
    const store = useStore();
    const { push } = useRouter();

    const loginemail = ref("");
    const password = ref("");
    const loading = ref(false);
    const hasError = ref(false);
    const error = ref([]);

    const initLogin = async () => {
      loading.value = true;

      store.commit("setCredentials", {
        email: loginemail.value,
        password: password.value
      });

      const client = await store.dispatch("getDirectusClient", true);
      const check = await client.testLogin();

      if (check.success) {
        store.commit("isLoggedIn", true);
        push("/");
        return;
      }

      hasError.value = true;
      error.value = check.error.message;
      loading.value = false;
    };

    return {
      loginemail,
      password,
      loading,
      hasError,
      error,
      initLogin
    };
  }
});
