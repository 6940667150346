<template>
  <div class="px-4">
    <h1 class="text-xl font-bold p-3">Your moodtable</h1>
    <div class="flex flex-col justify-items-center justify-center h-full w-full">
      <div class="login-block w-3/4 md:w-1/2 lg:w-1/4 mx-auto my-16">
        <h3 class="text-md font-bold p-3 whitespace-nowrap">Login here</h3>
        <div class="flex-row w-full border-black border-b-2 my-4 py-2">
          <label for="username" class="w-full font-italic pr-1 py-2 inline-block w-64">
            Login (email address):
          </label>
          <input class="py-1 px-6 ml-4 shadow-sm border border-gray ml-8 w-full" type="text"  id="loginemail" name="loginemail"  v-model="loginemail" />
        </div>
        <div class="flex-row w-full border-black border-b-2 my-4 py-2">
          <label for="password" class="w-full font-italic pr-1 py-2 inline-block w-64">
            Password:
          </label>
          <input class="py-1 px-6 ml-4 shadow-sm border border-gray ml-8 w-full" type="password"  id="password" name="password"  v-model="password" />
        </div>
        <div class="flex-row w-full mt-6">
          <button :disabled="loading" @click="initLogin" class="my-1 mx-6 py-2 px-8  bg-blue-800 text-white hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed">
            Login
          </button>
          <p v-show="hasError" class="bg-red-100 transition-all white-space-pre">{{error}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Login",
  setup () {
    const store = useStore();
    const { push } = useRouter();

    const loginemail = ref("");
    const password = ref("");
    const loading = ref(false);
    const hasError = ref(false);
    const error = ref([]);

    const initLogin = async () => {
      loading.value = true;

      store.commit("setCredentials", {
        email: loginemail.value,
        password: password.value
      });

      const client = await store.dispatch("getDirectusClient", true);
      const check = await client.testLogin();

      if (check.success) {
        store.commit("isLoggedIn", true);
        push("/");
        return;
      }

      hasError.value = true;
      error.value = check.error.message;
      loading.value = false;
    };

    return {
      loginemail,
      password,
      loading,
      hasError,
      error,
      initLogin
    };
  }
});
</script>
