import {
  createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw
} from "vue-router";
import Home from "../views/Home.vue";
import Week from "../views/Week.vue";
import NewEvent from "../views/NewEvent.vue";
import Login from "../views/Login.vue";
import { Store } from "vuex";
import { RootState } from "@/store";

const getStoreCombinedRouter = (store: Store<RootState>): Router => {
  const routes: Array<RouteRecordRaw> = [
    {
      path: "/",
      name: "Today",
      component: Home,
      meta: { needsAuthentication: true }
    },
    {
      path: "/week",
      name: "Week",
      component: Week,
      meta: { needsAuthentication: true }
    },
    {
      path: "/new-event",
      name: "New Event",
      component: NewEvent,
      meta: { needsAuthentication: true }
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    }
  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });

  router.beforeEach((to, from, next): void => {
    if (to.meta.needsAuthentication && !store.state.isLoggedIn) {
      return next("/login");
    }
    return next();
  });

  return router;
};

export default getStoreCombinedRouter;
