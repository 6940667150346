
import { defineComponent } from "vue";
import { find } from "lodash";
import { format, eachDayOfInterval, startOfWeek, endOfWeek, addWeeks, subWeeks } from "date-fns";
import { de } from "date-fns/locale";
import apiClientMixin from "@/mixins/apiClientMixin";
import DayColumn from "@/components/DayColumn.vue";
import { WeekPdf } from "@/utility/createPdf";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/vue/solid";

import { Day } from "../dbTypes";
import { hours, activeHours, eventHours, parseEvent } from "../mixins/activeHours";

export default defineComponent({
  name: "Home",
  components: { DayColumn, ChevronDoubleLeftIcon, ChevronDoubleRightIcon },
  mixins: [apiClientMixin],
  setup() {
    const deFormat = (date: Date) => format(date, "EEE, dd.MM.yyyy", { locale: de });
    return {
      format,
      deFormat
    };
  },
  data () {
    const today: Date = new Date();
    const days: Day[]|null = null;
    const loading = false;
    return { days, today, loading };
  },
  computed: {
    thisStartOfWeek () { return startOfWeek(this.today, { weekStartsOn: 1 }) },
    thisEndOfWeek () { return endOfWeek(this.today, { weekStartsOn: 1 }) },
    weekdays () {
      const weekdays = eachDayOfInterval({ start: this.thisStartOfWeek, end: this.thisEndOfWeek });
      return weekdays.map((day) => {
        const formattedDay = format(day, "yyyy-MM-dd");
        if (this.days != null) {
          const dayData = find(this.days, (dataDay: Day) => dataDay.date === formattedDay);
          if (dayData) {
            return {
              day,
              formattedDay,
              dayData
            };
          }
        }
        return { day, formattedDay };
      });
    },
    cleanActiveHours() {
      const curEventHours = this.weekdays.map((day) => {
        const eventedDay = find(this.days, ({ date }) => date === day.formattedDay);
        return eventedDay ? eventHours(eventedDay) : [];
      });
      const curActiveHours = curEventHours.map(activeHours);
      return curActiveHours;
    }
  },
  async mounted () {
    await this.getMoodData();
  },
  methods: {
    async weekBack() {
      this.today = subWeeks(this.today, 1);
      this.loading = true;
      await this.getMoodData();
      this.loading = false;
    },
    async weekForward() {
      this.today = addWeeks(this.today, 1);
      this.loading = true;
      await this.getMoodData();
      this.loading = false;
    },
    createHeadlines() {
      const headlines = this.weekdays.map(({ formattedDay }) => formattedDay);
      return headlines;
    },
    createRows(parse = false) {
      const rows = hours.map((h) => this.cleanActiveHours.map((ih) => (parse ? parseEvent(ih[h]) : ih[h])));
      // console.log({ cleanActiveHours: this.cleanActiveHours, headlines, rows });
      return rows;
    },
    createCSV() {
      const headlines = this.createHeadlines();
      const rows = this.createRows(true);

      let csvString = `;${headlines.join(";")}\n`;
      rows.forEach((row, i) => {
        csvString += `${i + 1};`;
        csvString += row.join(";");
        csvString += "\n";
      });

      return csvString;
    },
    exportCSV() {
      if (window.document) {
        const csvContent = this.createCSV();
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const uri = URL.createObjectURL(blob);
        const link = this.$refs.downloadLink;
        link.setAttribute("href", uri);
        link.setAttribute("download", `moodWeek_${format(this.thisStartOfWeek, "yyyy-MM-dd")}_${format(this.thisEndOfWeek, "yyyy-MM-dd")}.csv`);
        link.click(); // This
      }
    },
    exportPDF() {
      const headlines = this.createHeadlines();
      const rows = this.createRows().map((row) => row.map((hour) => {
        if (!hour || !hour.active) { return "-" }
        return `(${hour.rating || "0"})` + (hour.start === hour.hour ? " " + hour.note : "");
      }));

      const weekPdf = new WeekPdf(headlines, rows);
      weekPdf.createTable();
      weekPdf.getPdf(`moodWeek_${format(this.thisStartOfWeek, "yyyy-MM-dd")}_${format(this.thisEndOfWeek, "yyyy-MM-dd")}.pdf`);
    },
    async getMoodData () {
      await this.getClient();
      await this.clientLogin();
      const dayClient = this.client.getItemClient("day");
      const result = await dayClient.readMany({
        filter: {
          date:
            {
              _gte: format(this.thisStartOfWeek, "yyyy-MM-dd"),
              _lte: format(this.thisEndOfWeek, "yyyy-MM-dd")
            },
          user_created: "$CURRENT_USER"
        },
        fields: [
          "date",
          "note",
          "event.id",
          "event.start",
          "event.end",
          "event.rating",
          "event.note",
          "event.day"
        ]
      });

      console.log(result);
      if (result.data) {
        this.days = result.data;
      }
    }
  }
});
