<template>
  <div class="px-4">
    <div class="flex flex-row">
      <div class="w-full md:w-1/2 py-2 place-items-center place-content-center">
        <button @click="weekBack" class="align-middle mb-2 inline-block border border-black bg-color-gray-400 rounded-md hover:bg-color-blue-400 hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed" :disabled="loading">
          <chevron-double-left-icon class="h-4 w-8 my-2 px-2 color-blue-800" />
        </button>
        <h1 class="text-xl font-bold p-3 inline-block">
          {{ T('Your moods in week') }} {{deFormat(thisStartOfWeek)}} - {{deFormat(thisEndOfWeek)}}
        </h1>
        <button @click="weekForward" class="align-middle mb-2 inline-block border border-black bg-color-gray-400 rounded-md hover:bg-color-blue-400 hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed" :disabled="loading">
          <chevron-double-right-icon class="h-4 w-8 my-2 px-2 color-blue-800" />
        </button>
      </div>
      <div class="w-full md:w-1/2 py-2 place-items-end place-content-end text-right">
        <button @click="exportCSV" class="align-middle mb-2 p-2 inline-block border border-black bg-color-gray-400 rounded-md hover:bg-color-blue-400 hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed" :disabled="loading">
          Export CSV
        </button>
        <button @click="exportPDF" class="align-middle mb-2 p-2 inline-block border border-black bg-color-gray-400 rounded-md hover:bg-color-blue-400 hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed" :disabled="loading">
          Export PDF
        </button>
        <a style="visibility:hidden;" ref="downloadLink">&nbsp;</a>
      </div>
    </div>
    <h2 class="text-lg font-bold p-3">
     <span class="mb-4 md:mb-0 inline-block"> {{T("Color code")}} </span> <br class="inline-block md:hidden" />
      <span class="border-2 border-black m-2-lr p-2">
        <span class="h-6 w-6 md:w-12 inline-block sign-hours active rated--2">&nbsp;</span> --
      </span>
      <span class="border-2 border-black m-2-lr p-2">
        <span class="h-6 w-6 md:w-12 inline-block sign-hours active rated--1">&nbsp;</span> -
      </span>
      <span class="border-2 border-black m-2-lr p-2">
        <span class="h-6 w-6 md:w-12 inline-block sign-hours active rated-0">&nbsp;</span> 0
      </span>
      <span class="border-2 border-black m-2-lr p-2">
        <span class="h-6 w-6 md:w-12 inline-block sign-hours active rated-1">&nbsp;</span> +
      </span>
      <span class="border-2 border-black m-2-lr p-2">
        <span class="h-6 w-6 md:w-12 inline-block sign-hours active rated-2">&nbsp;</span> ++
      </span>
    </h2>
    <div class="day-flow flex flex-row w-screen md:w-full overflow-x-auto">
      <div v-for="day in weekdays" :key="`day-column-${day.formattedDay}`" class="day flex-grow">
        <h3 class="text-md font-bold p-3 whitespace-nowrap">{{T("Moods on")}} {{deFormat(day.day)}}</h3>
        <day-column :today="day.dayData || null" compress-notes in-week />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { find } from "lodash";
import { format, eachDayOfInterval, startOfWeek, endOfWeek, addWeeks, subWeeks } from "date-fns";
import { de } from "date-fns/locale";
import apiClientMixin from "@/mixins/apiClientMixin";
import DayColumn from "@/components/DayColumn.vue";
import { WeekPdf } from "@/utility/createPdf";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/vue/solid";

import { Day } from "../dbTypes";
import { hours, activeHours, eventHours, parseEvent } from "../mixins/activeHours";

export default defineComponent({
  name: "Home",
  components: { DayColumn, ChevronDoubleLeftIcon, ChevronDoubleRightIcon },
  mixins: [apiClientMixin],
  setup() {
    const deFormat = (date: Date) => format(date, "EEE, dd.MM.yyyy", { locale: de });
    return {
      format,
      deFormat
    };
  },
  data () {
    const today: Date = new Date();
    const days: Day[]|null = null;
    const loading = false;
    return { days, today, loading };
  },
  computed: {
    thisStartOfWeek () { return startOfWeek(this.today, { weekStartsOn: 1 }) },
    thisEndOfWeek () { return endOfWeek(this.today, { weekStartsOn: 1 }) },
    weekdays () {
      const weekdays = eachDayOfInterval({ start: this.thisStartOfWeek, end: this.thisEndOfWeek });
      return weekdays.map((day) => {
        const formattedDay = format(day, "yyyy-MM-dd");
        if (this.days != null) {
          const dayData = find(this.days, (dataDay: Day) => dataDay.date === formattedDay);
          if (dayData) {
            return {
              day,
              formattedDay,
              dayData
            };
          }
        }
        return { day, formattedDay };
      });
    },
    cleanActiveHours() {
      const curEventHours = this.weekdays.map((day) => {
        const eventedDay = find(this.days, ({ date }) => date === day.formattedDay);
        return eventedDay ? eventHours(eventedDay) : [];
      });
      const curActiveHours = curEventHours.map(activeHours);
      return curActiveHours;
    }
  },
  async mounted () {
    await this.getMoodData();
  },
  methods: {
    async weekBack() {
      this.today = subWeeks(this.today, 1);
      this.loading = true;
      await this.getMoodData();
      this.loading = false;
    },
    async weekForward() {
      this.today = addWeeks(this.today, 1);
      this.loading = true;
      await this.getMoodData();
      this.loading = false;
    },
    createHeadlines() {
      const headlines = this.weekdays.map(({ formattedDay }) => formattedDay);
      return headlines;
    },
    createRows(parse = false) {
      const rows = hours.map((h) => this.cleanActiveHours.map((ih) => (parse ? parseEvent(ih[h]) : ih[h])));
      // console.log({ cleanActiveHours: this.cleanActiveHours, headlines, rows });
      return rows;
    },
    createCSV() {
      const headlines = this.createHeadlines();
      const rows = this.createRows(true);

      let csvString = `;${headlines.join(";")}\n`;
      rows.forEach((row, i) => {
        csvString += `${i + 1};`;
        csvString += row.join(";");
        csvString += "\n";
      });

      return csvString;
    },
    exportCSV() {
      if (window.document) {
        const csvContent = this.createCSV();
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const uri = URL.createObjectURL(blob);
        const link = this.$refs.downloadLink;
        link.setAttribute("href", uri);
        link.setAttribute("download", `moodWeek_${format(this.thisStartOfWeek, "yyyy-MM-dd")}_${format(this.thisEndOfWeek, "yyyy-MM-dd")}.csv`);
        link.click(); // This
      }
    },
    exportPDF() {
      const headlines = this.createHeadlines();
      const rows = this.createRows().map((row) => row.map((hour) => {
        if (!hour || !hour.active) { return "-" }
        return `(${hour.rating || "0"})` + (hour.start === hour.hour ? " " + hour.note : "");
      }));

      const weekPdf = new WeekPdf(headlines, rows);
      weekPdf.createTable();
      weekPdf.getPdf(`moodWeek_${format(this.thisStartOfWeek, "yyyy-MM-dd")}_${format(this.thisEndOfWeek, "yyyy-MM-dd")}.pdf`);
    },
    async getMoodData () {
      await this.getClient();
      await this.clientLogin();
      const dayClient = this.client.getItemClient("day");
      const result = await dayClient.readMany({
        filter: {
          date:
            {
              _gte: format(this.thisStartOfWeek, "yyyy-MM-dd"),
              _lte: format(this.thisEndOfWeek, "yyyy-MM-dd")
            },
          user_created: "$CURRENT_USER"
        },
        fields: [
          "date",
          "note",
          "event.id",
          "event.start",
          "event.end",
          "event.rating",
          "event.note",
          "event.day"
        ]
      });

      console.log(result);
      if (result.data) {
        this.days = result.data;
      }
    }
  }
});
</script>

<style lang="scss">
 .day-flow {
   & > .day {
     min-width: 350px;
     padding: 0 1px;
     border-right: 1px solid grey;
   }
 }
</style>
