import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import translationPlugin from "./translation/plugin";
import getStoreCombinedRouter from "./router";
import store from "./store";
import "./style/tailwind.css";
import "./style/index.scss";
import "./style/custom.css";

createApp(App)
  .use(translationPlugin, { lang: "de_DE" })
  .use(store)
  .use(getStoreCombinedRouter(store))
  .mount("#app");
