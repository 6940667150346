import { defineComponent } from "vue";
import { map, some, find } from "lodash";

import { Day, DayEvent } from "../dbTypes";

export interface ActiveHour {
  id?: number;
  active: boolean;
  note: string;
  start: string | number;
  end: string | number;
  hour?: string | number;
  rating?: number;
}

export const hours = (() => {
  const hoursArray = Array.from(new Array(24).keys());
  return hoursArray.map(k => k + 1);
})();

export function activeHour(hour: number, eventHours: DayEvent[]): boolean {
  const result = some(
    eventHours,
    (activeHour: DayEvent) =>
      (activeHour.startHour || 99) <= hour && (activeHour.endHour || 0) > hour
  );
  return result;
}

export function activeHourSelect(
  hour: number,
  eventHours: DayEvent[]
): DayEvent | null {
  const result = find(
    eventHours,
    (activeHour: DayEvent) =>
      (activeHour.startHour || 99) <= hour && (activeHour.endHour || 0) > hour
  );
  return result || null;
}

export function parseEvent(event: ActiveHour): string {
  if (event && event.active) {
    let returner = `| ${event.rating || 0}`;
    if (event.start === event.hour) {
      returner += ` (${event.note})`;
    }
    return returner;
  }
  return "";
}

export function eventHours(toDay = null): DayEvent[] {
  if (toDay && toDay.event) {
    const eventHours: DayEvent[] = map(toDay.event, (event: DayEvent) => {
      const startHour = new Date(event.start as string).getHours();
      const endHour = new Date(event.end as string).getHours();
      return {
        ...event,
        startHour,
        endHour
      };
    }) as DayEvent[];
    return eventHours;
  }
  return [];
}

export function activeHours(eventHours: DayEvent[]): ActiveHour[] {
  return hours.map((hour: number) => {
    const selectedHour = activeHourSelect(hour, eventHours);
    return {
      hour,
      active: activeHour(hour, eventHours),
      note: selectedHour?.note || "",
      rating: selectedHour?.rating || "",
      start: selectedHour?.startHour || hour,
      end: selectedHour?.endHour || hour + 1
    };
  }) as ActiveHour[];
}

export default defineComponent({
  props: {
    today: {
      type: Object as () => Day | null,
      default: () => null
    }
  },
  computed: {
    hours() {
      return hours;
    },
    eventHours(): DayEvent[] {
      if (this.today === null) {
        return [];
      }
      const toDay: Day = (this.today as unknown) as Day;

      return eventHours(toDay);
    },
    activeHours(): ActiveHour[] {
      return activeHours(this.eventHours);
    }
  },
  methods: {
    activeHour(hour) {
      return activeHour(hour, this.eventHours);
    },
    activeHourSelect(hour) {
      return activeHourSelect(hour, this.eventHours);
    }
  }
});
