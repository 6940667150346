

import { defineComponent } from "vue";
import { format } from "date-fns";
import { Day } from "../dbTypes";
import apiClientMixin from "@/mixins/apiClientMixin";
import DayColumn from "@/components/DayColumn.vue";

export default defineComponent({
  name: "TodayView",
  components: { DayColumn },
  mixins: [apiClientMixin],
  data () {
    const today: Day|null = null;
    return { today };
  },
  computed: {
    isoToday (): string {
      return format(new Date(), "yyyy-MM-dd");
    }
  },
  async mounted () {
    await this.getMoodData();
  },
  methods: {
    async getMoodData () {
      await this.getClient();
      await this.clientLogin();
      const dayClient = this.client.getItemClient("day");
      dayClient.readMany({
        filter: {
          date: { _eq: this.isoToday },
          user_created: "$CURRENT_USER"
        },
        fields: [
          "date",
          "note",
          "event.start",
          "event.end",
          "event.rating",
          "event.note",
          "event.day"
        ]
      }).then((result) => {
        console.log(result);
        if (result.data[0]) {
          this.today = result.data[0];
        }
      });
    }
  }
});
