import { Auth, Directus, IAuth, IItems, MemoryStorage } from "@directus/sdk";
import { ApiTransport } from "./ApiTransport";

export class DirectusClient {
  private _isLoggedIn = false;
  private _credentials: { email: string; password: string } | null = null;

  public dbClient: Directus<any>;

  constructor(credentials = null) {
    const url = "https://directus.itologie.de/";
    const storage = new MemoryStorage();
    const transport = new ApiTransport(url, storage);
    const auth = new Auth(transport, storage, {
      mode: "json" // or cookie, depends on your use  case
    });
    this.dbClient = new Directus(url, {
      auth,
      storage,
      transport
    });

    this._credentials = credentials;
  }

  async getAuth(credentials: {
    email: string;
    password: string;
  }): Promise<void> {
    this._credentials = credentials;
    this._isLoggedIn = true;
    await this.dbClient.auth.login(credentials);
  }

  async testLogin(): Promise<{ success: boolean; error?: Error }> {
    try {
      await this.login();
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  }

  async login(): Promise<void> {
    if (this._isLoggedIn) {
      await this.dbClient.auth.refresh();
      return;
    }
    if (this._credentials !== null) {
      this._isLoggedIn = true;
      await this.dbClient.auth.login(this._credentials);
      return;
    }
    throw new Error("Cannot login: No valid credentials");
  }

  get authData(): IAuth {
    return this.dbClient.auth;
  }

  public getItemClient(item: string): IItems<Record<string, unknown>> {
    return this.dbClient.items(item);
  }
}
