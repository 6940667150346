import JsPDF from "jspdf";
import autoTable, {
  CellHookData,
  RowInput,
  UserOptions
} from "jspdf-autotable";

import { ActiveHour } from "@/mixins/activeHours";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { first, last } from "lodash";

export interface WeekDay {
  date: Date;
  hours: ActiveHour[];
}

export class WeekPdf {
  private document: JsPDF;
  private headlines: string[];
  private rows: string[][];
  private weekData: WeekDay[];

  constructor(headlines: string[], rows: string[][]) {
    this.headlines = headlines;
    this.rows = rows;
    this.document = new JsPDF({
      orientation: "l",
      unit: "mm",
      format: "a4"
    });
  }

  private get head() {
    return [["Stunde", ...this.headlines]];
  }

  private get body(): RowInput[] {
    return this.rows.map(
      (row, i): RowInput => {
        row.unshift(String(i + 1));
        return row;
      }
    );
  }

  createTable(): void {
    const autoTableBodyOptions: UserOptions = {
      theme: "grid",
      head: this.head,
      body: this.body,
      styles: { cellPadding: 0.25 },
      willDrawCell: (cellHookData: CellHookData) => {
        const cellText = cellHookData.cell.text.join("");
        this.document.setFillColor("#ffffff");
        this.document.setTextColor("#ffffff");

        if (/^\(2\)\s?.*$/.test(cellText)) {
          this.document.setFillColor("#1dba00");
          this.document.setTextColor("#121212");
        }
        if (/^\(1\)\s?.*$/.test(cellText)) {
          this.document.setFillColor("#d4ffcc");
          this.document.setTextColor("#121212");
        }
        if (/^\(0\)\s?.*$/.test(cellText)) {
          this.document.setFillColor("#efefef");
          this.document.setTextColor("#121212");
        }
        if (/^\(-1\)\s?.*$/.test(cellText)) {
          this.document.setFillColor("#ffb13d");
          this.document.setTextColor("#121212");
        }
        if (/^\(-2\)\s?.*$/.test(cellText)) {
          this.document.setFillColor("#ed0000");
          this.document.setTextColor("#ffff4a");
        }
        if (!/^\((2|1|0|-1|-2)\)\s?.*$/.test(cellText)) {
          this.document.setFillColor("#dedede");
          this.document.setTextColor("#121212");
        }
      }
    };
    console.log({ autoTableBodyOptions });
    autoTable(this.document, autoTableBodyOptions);
  }

  getPdf(name: string): void {
    this.document.save(name);
  }
}
