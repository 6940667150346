import { App } from "vue";
import Translations from "./translations";

export default {
  install: (app: App, options: Record<string, unknown>): void => {
    const option = { lang: "en_EN", ...options };
    const currentTranslation = Translations[option.lang as string] || {};
    app.config.globalProperties.T = key => {
      return currentTranslation[key] || key;
    };
  }
};
