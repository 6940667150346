import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

import { DirectusClient } from "@/apiconnection/dbClient";

export interface RootState {
  isLoggedIn: boolean;
  loginCredentials: { email: string; password: string } | null;
  client: null | DirectusClient;
}

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  reducer: ({ isLoggedIn, loginCredentials }) => ({
    isLoggedIn,
    loginCredentials
  })
});

export default createStore<RootState>({
  state: {
    isLoggedIn: false,
    loginCredentials: null,
    client: null
  },
  mutations: {
    isLoggedIn(state, newVal) {
      state.isLoggedIn = newVal;
    },
    setCredentials(state, creds) {
      state.loginCredentials = creds;
    },
    setClient(state, newClient) {
      state.client = newClient;
    }
  },
  actions: {
    async getDirectusClient({ state, commit }, force = false) {
      if (!state.client || force) {
        const directusClient = new DirectusClient(state.loginCredentials);
        commit("setClient", directusClient);
      }
      return state.client;
    }
  },
  plugins: [vuexLocal.plugin]
});
