<template>
  <section class="day-columm flex" :class="{inWeek}">
    <div class="flex flex-col times">
      <div v-for="hour in hours" :key="`clock-${hour}`" class="pb-0 pt-1 px-4 h-10 border-b-2 border-r-2 border-black">
        {{ hour }}:00
      </div>
    </div>
    <div class="elements flex flex-col">
      <div
        v-for="activeHour in activeHours"
        :key="`sign-${activeHour.hour}`"
        :id="`sign-${activeHour.hour}`"
        class="sign-hours flex pb-0 pt-1 px-4 h-10 border-black"
        :class="{
          active: activeHour.active,
          start: activeHour.start === activeHour.hour,
          end: activeHour.end === activeHour.hour,
          [`rated-${activeHour.rating||0}`]: activeHour.active
        }"
      >
        <span class="notes" :class="{compress: compressNotes, 'relative z-10': activeHour.start === activeHour.hour}">
          {{activeHour.start === activeHour.hour || activeHour.start === activeHour.end ? activeHour.note : ""}}
        </span>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import activeHours from "../mixins/activeHours";

export default defineComponent({
  name: "DayColumn",
  components: {},
  mixins: [activeHours],
  props: {
    inWeek: {
      type: Boolean,
      default: false
    },
    compressNotes: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss">
.day-columm {

  .elements {
    flex-grow: 1;
  }

  &.inWeek {
    width: 320px;
    .elements {
      width: 250px;
    }
  }

    .notes {
      max-width: 80%;
      &.compress {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
