import {
  AxiosTransport,
  TransportOptions,
  TransportResponse
} from "@directus/sdk";

export class ApiTransport extends AxiosTransport {
  extendHeaders(options: Record<string, unknown>): Record<string, unknown> {
    const currentHeaders = options.headers as Record<string, unknown>;
    options.headers = {
      ...currentHeaders,
      "Cache-Control": "no-cache"
    };

    return options;
  }

  get<T = any>(
    path: string,
    options?: TransportOptions
  ): Promise<TransportResponse<any, any>> {
    return super.get(path, this.extendHeaders(options));
  }

  head<T = any>(
    path: string,
    options?: TransportOptions
  ): Promise<TransportResponse<any, any>> {
    return super.head(path, this.extendHeaders(options));
  }

  options<T = any>(
    path: string,
    options?: TransportOptions
  ): Promise<TransportResponse<any, any>> {
    return super.options(path, this.extendHeaders(options));
  }

  delete<T = any, D = any>(
    path: string,
    data?: D,
    options?: TransportOptions
  ): Promise<TransportResponse<any, any>> {
    return super.delete(path, data, this.extendHeaders(options));
  }

  put<T = any, D = any>(
    path: string,
    data?: D,
    options?: TransportOptions
  ): Promise<TransportResponse<any, any>> {
    return super.put(path, data, this.extendHeaders(options));
  }

  post<T = any, D = any>(
    path: string,
    data?: D,
    options?: TransportOptions
  ): Promise<TransportResponse<any, any>> {
    return super.post(path, data, this.extendHeaders(options));
  }

  patch<T = any, D = any>(
    path: string,
    data?: D,
    options?: TransportOptions
  ): Promise<TransportResponse<any, any>> {
    return super.patch(path, data, this.extendHeaders(options));
  }
}
